import { z } from "zod"
import {
  TALENT_SIGNAL_STATUS,
  TALENT_SIGNAL_TYPE,
} from "~/consts/talentSignals"
import { numericFilterSchema, textFilterSchema } from "~/utils/signal"
import { signalDateFilterSchema } from "~/utils/signalDate"
import { peopleAdvancedFiltersSchema, peopleFiltersValidation } from "./people"

export const talentSignalFiltersSchema = z
  .object({
    SignalDate: signalDateFilterSchema,
    SignalScore: numericFilterSchema(),
    SignalType: z.array(z.nativeEnum(TALENT_SIGNAL_TYPE)),
    SignalStatus: z.array(z.nativeEnum(TALENT_SIGNAL_STATUS)),
    OutOfStealthAdvantage: numericFilterSchema(),
    TalentLatestSignal: z.enum(["Yes", "No"]),
  })
  .partial()

const HIDDEN_FILTERS = z
  .object({
    PastCompany: z.array(z.string()),
    NewCompany: z.array(z.string()),
    TalentTags: textFilterSchema,
    NewCompanyTagline: textFilterSchema,
    SpecterPersonIDs: z.array(z.string()),
  })
  .partial()

export const talentSignalFiltersValidation = talentSignalFiltersSchema
  .merge(peopleFiltersValidation)
  .merge(HIDDEN_FILTERS)

export type TalentSignalFilters = z.infer<typeof talentSignalFiltersValidation>

export const talentAdvancedFiltersSchema = z
  .object({
    Signal: z.object({
      Signal: talentSignalFiltersSchema,
    }),
  })
  .merge(peopleAdvancedFiltersSchema(true))
